/**
 * Global Styles - App
 *
 * Any global styles overrides or additional styles can be included in this file
 */
import React from 'react';
import {
  createGlobalStyle,
  Normalize,
  thd,
  down,
  css,
} from '@smooth-ui/core-sc';
import loriGlobalStyles from '_platform/src/theme/globalStyles';

export const GlobalStylesApp = createGlobalStyle`
  ${loriGlobalStyles}

  body {
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.6;
    background-color: ${thd('bodyBackground', '#F4EFEA')};
  }

  /* Custom CSS here */

  .page-container {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  h1 {
    font-size: 48px;
    font-weight: bold;
    font-variation-settings: 'wght' 400;
    line-height: 1.25;
    margin-bottom: .25em;
    ${down(
      'sm',
      css`
        font-size: 40px;
      `
    )};
  }

  h2 {
    font-weight: bold;
    font-size: 33px;
    line-height: 1.25;
    color: ${thd('primary', '#EC1C24')};
    ${down(
      'sm',
      css`
        font-size: 28px;
      `
    )};
  }

  h3 {
    font-weight: normal;
    font-size: 25px;
    font-weight: bold;
    ${down(
      'sm',
      css`
        font-size: 20px;
      `
    )};
  }

  p a {
    color: ${thd('primary', '#EC1C24')};
    &:hover,&:focus {
      text-decoration: none;
    }
  }

    .button {
      background-color: ${thd('secondary', '#203C70')};
      color: white;
      padding: 10px 20px;
      border-radius: 30px;
      text-decoration: none;
      font-weight: bold;

      &:hover,&:focus {
        background-color: ${thd('primary', '#EC1C24')};
      }
    }

  /* Don't wrap emails, phones or other non-wrapping strings */
  .nowrap,
  .date,
  .email,
  .phone {
    white-space: nowrap;
  }

  .d-flex, .d--flex {
    display: flex;
  }

  /* Color text */
  .text--red {
    color: ${thd('danger', '#dc3545')};
  }

  .text--white {
    color: #fff;
  }

  .text--primary {
    color: ${thd('primary', '#867455')};
  }

  /* Align text */
  .text-center,
  .text-centre,
  .text--center,
  .text--centre {
    text-align: center;
  }
  .text-right,
  .text--right {
    text-align: right;
  }
  .text-left,
  .text--left {
    text-align: left;
  }

  .text-small,
  .text--small {
    font-size: 80%;
  }

  .text-large,
  .text--large {
    font-size: 120%;
  }

  .text-larger,
  .text--larger {
    font-size: 200%;
  }

  .text-normal,
  .text--normal {
    font-weight: normal;
  }
  .text-uppercase,
  .text--uppercase {
    text-transform: uppercase;
  }

  .text--tight {
    margin-bottom: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-top: 0;
  }

  /* Handy spacing classes */
  :root {
    --spacer: 2rem;
  }

  .spacer {
    padding-bottom: 40px;
    padding-top: 40px;
    padding-bottom: var(--spacer);
    padding-top: var(--spacer);
  }
  .spacer--small {
    padding-bottom: 20px;
    padding-top: 20px;
    padding-bottom: calc(var(--spacer) / 2);
    padding-top: calc(var(--spacer) / 2);
  }
  .spacer--large {
    padding-bottom: 80px;
    padding-top: 80px;
    padding-bottom: calc(var(--spacer) * 2);
    padding-top: calc(var(--spacer) * 2);
  }
  .spacer--around {
    padding: 40px;
    padding: var(--spacer);
  }
  .spacer--around--small {
    padding: 20px;
    padding: calc(var(--spacer) / 2);
  }
  .spacer--around--large {
    padding: 80px;
    padding: calc(var(--spacer) * 2);
  }
  .spacer--top {
    padding-top: 40px;
    padding-top: var(--spacer);
  }
  .spacer--bottom {
    padding-bottom: 40px;
    padding-bottom: var(--spacer);
  }
  .spacer--top--small,
  .spacer--small--top {
    padding-top: 20px;
    padding-top: calc(var(--spacer) / 2);
  }
  .spacer--bottom--small,
  .spacer--small--bottom {
    padding-bottom: 20px;
    padding-bottom: calc(var(--spacer) / 2);
  }
  .spacer--top--large,
  .spacer--large--top {
    padding-top: 80px;
    padding-top: calc(var(--spacer) * 2);
  }
  .spacer--bottom--large,
  .spacer--large--bottom {
    padding-bottom: 80px;
    padding-bottom: calc(var(--spacer) * 2);
  }

  .Toastify .Toastify__toast--success {
    background: #07bc0c;
  }
`;

const StylesApp = () => (
  <React.Fragment>
    <Normalize />
    <GlobalStylesApp />
  </React.Fragment>
);

export default StylesApp;
