/**
 * Application theme
 *
 * Override and extend smooth-ui theme values
 * https://github.com/smooth-code/smooth-ui/blob/master/packages/shared/core/theme.js
 * https://smooth-ui.smooth-code.com/
 */

import { transparentize } from 'polished';
import { thd, theme } from '@smooth-ui/core-sc';
import settingsApp from 'theme/settingsApp';

/*
If overwriting the default Smooth-UI theme properties, and you're planning on
re-using them throughout the default export below, make sure to define them in
the outer scope, otherwise components attempting to use the value with throw errors
*/
const primary = '#EC1C24'; //red
const secondary = '#203C70'; //blue
const grey = '#979797';
const bluegrey = '#87B1C3';
const green = '#BFD230';
const text = '#131D28';
const bodyBackground = '#F4EFEA';

export default {
  // Mandatory
  ...theme,
  settingsApp, // Bring in the merged app settings

  // Resets
  borderRadius: 0,
  error: thd('danger', '#dc3545'),
  gridMaxWidths: { sm: '100%', md: '100%', lg: '100%', xl: '1200px' },
  primary: primary,
  secondary: secondary,
  text: text,
  grey: grey,
  bluegrey: bluegrey,
  green: green,
  bodyBackground: bodyBackground,

  // Layout
  borderColor: thd('secondary', '#203C70'),
  containerWidth: '1200px',

  // Colours
  defaultFontColor: theme.gray800,
  // pointsValueColor: '#f90',

  // Input + Buttons
  inputBtnMinWidth: '120px',
  customInputHintColor: theme.gray600,
  customInputHintFontSize: '.875em',
  customInlineInputInvalidColor: theme.red,
  customCheckboxDisabledBackground: '#ced4da',

  // Header + Menu
  customHeaderBackground: '#151d29',
  customHeaderColor: '#f8fbfe',
  customMenuBackground: '#151d29',
  customMenuColor: primary,
  customMenuHighlight: '#151d29',
  customMenuHighlightColor: secondary,
  customMenuFocus: transparentize(0.75, '#f4bd19'),
  customSubMenuBackground: '#ece3da',
  customMobileMenuOverlayBackground: 'rgba(0, 0, 0, 0.5)',

  // Loading animations
  loadingBarColor: primary || '#f4bd19',
  loadingSpinnerPrimary: '#555',
  loadingSpinnerSecondary: '#eee',

  // Welcome component
  welcomeBackground: primary,
  welcomeColor: 'white',
  welcomeHighlight: 'white',

  // Breadcrumbs
  breadcrumbsBackground: 'transparent',
  breadcrumbsColor: 'black',
  breadcrumbsContainerWidth: thd('containerWidth', '100%'),
  breadcrumbsLinkColor: 'black',
  breadcrumbsLinkHoverColor: thd('primary', '#EC1C24'),
  breadcrumbsCurrentLinkColor: 'black',
  breadcrumbsFontSize: '0.75rem',

  // Cart
  // miniCartBackground: '#fff',
  cartBadgeBackground: '#0D0D0D',
  cartBadgeColor: '#fff',
  cartPointsBalanceBackground: transparentize(0.25, secondary),
  cartRemainingNegativeColor: thd('danger', '#dc3545'),

  // Rewards
  rewardCategoriesListContainerWidth: thd('containerWidth', '100%'),
  rewardCategoriesFilterContainerWidth: thd('containerWidth', '100%'),
  rewardListContainerWidth: thd('containerWidth', '100%'),
  rewardDetailContainerWidth: thd('containerWidth', '100%'),
  rewardDescription1ContainerWidth: thd('containerWidth', '100%'),
  rewardDescription2ContainerWidth: thd('containerWidth', '100%'),

  // Rewards - Quickview
  // quickviewBackground: '#333',
  // quickviewColor: '#fff',

  // Rewards - Wishlist
  // miniWishlistBackground: '#fff',
  wishlistBadgeBackground: '#0D0D0D',
  wishlistBadgeColor: 'white',
  //wishlistContainerWidth: '500px',
  wishlistIconActive: 'white',
  wishlistIconInactive: theme.gray500,

  // Rewards - Highlight (featured/related rewards)
  rewardsHighlightBackground: '#fff',
  rewardsHighlightContainerWidth: thd('containerWidth', '100%'),

  // Statement
  statementHighlightColor: thd('secondary', '#203C70'),
  statementStatus: {
    achieved: '#1e967a',
    onTrack: '#1e967a',
    close: '#ea7c22',
    behind: '#dc402a',
    inProgress: '#ea7c22',
    timeElapsed: '#32343b',
  },
  statementDoughnutBorder: '#eaeaeb',
  statementDoughnutRemainingTrack: '#fff',

  // Tooltips
  tooltipBackground: '#fff',
  tooltipBorderColor: '#fff',
  tooltipColor: thd('defaultFontColor', theme.gray800),

  // Pagination
  pagerActiveBackground: 'rgba(0,0,0,0.125)',
  pagerActiveColor: '#fff',
  // pagerColor: thd('gray800', 'inherit'),
  pagerBackgroundHover: 'rgba(0,0,0,0.125)',
};
